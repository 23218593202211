import React, {useState, useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {confirmAlert} from 'react-confirm-alert';
import {useForm} from 'react-hook-form';
import useRolesHook from '../../../api/roles';
import usePermissionsHook from '../../../api/permissions';
import useClientPermissionsHook from '../../../api/clientPermissions';
import {
    Spinner,
    ViewRoles,
    Pagination,
    FormRoles,
    Confirm, Search,
} from '../../../components';

const Roles = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');

    const {getRoles, postRole, updateRole, deleteRole} = useRolesHook({page, q});
    const {getPermissions} = usePermissionsHook({limit: 1000000});
    const {getClientPermissions} = useClientPermissionsHook({limit: 1000000});

    const {register, handleSubmit, setValue, reset, formState: {errors}} = useForm();

    const {data, isLoading, isError, error, refetch} = getRoles;
    const {data: permissionData} = getPermissions;
    const {data: clientPermissionData} = getClientPermissions;

    const formCleanHandler = () => {
        setEdit(false);
        reset();
    };

    // Refetch data when the page changes
    // Refetch data when the search query changes
    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1);
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => deleteRole.mutateAsync(id)));
    };

    const submitHandler = (data) => {
        if (edit) {
            updateRole.mutateAsync({
                _id: id,
                name: data.name,
                description: data.description,
                permission: data.permission,
                clientPermission: data.clientPermission,
            }).then(formCleanHandler);
        } else {
            postRole.mutateAsync(data).then(formCleanHandler);
        }
    };

    const editHandler = (role) => {
        setId(role._id);
        setEdit(true);
        setValue('name', role.name);
        setValue('description', role.description);
        setValue('permission', role.permission?.map((item) => item._id));
        setValue('clientPermission', role.clientPermission?.map((item) => item._id));
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Roles</title>
                    <meta property="og:title" content="Roles" key="title"/>
                </Helmet>
            </HelmetProvider>

            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    Roles List <sup className="fs-6">[{data?.total}]</sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#roleModal'
                        onClick={() => formCleanHandler(true)}
                    >
                        Add New Role
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by name'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>


            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <div className="alert alert-danger">{error}</div>
            ) : (
                <ViewRoles
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormRoles
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                permissionData={permissionData?.data}
                clientPermissionData={clientPermissionData?.data}
            />
        </>
    );
};

export default Roles;
