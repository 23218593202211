import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import "../../css/language-switcher.css";
import {languageOptions} from "../../config/languagesConfig";

function LanguageSwitcherDropdown() {
    const {i18n} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);

    // Initialize selectedLanguage state based on i18n's current language
    const getCurrentLanguageIcon = useCallback(
        () => i18n.language || languageOptions[0].value,
        [i18n.language]
    );

    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguageIcon());
    const getLanguageOption = (language) => languageOptions.find(option => option.value === language) || languageOptions[0];

    useEffect(() => {
        // This effect syncs the component's state with i18n's current language
        // whenever the language changes, for example, through a different part of your app
        // or if the language was changed and persisted before a page refresh.
        setSelectedLanguage(getCurrentLanguageIcon());
    }, [ getCurrentLanguageIcon]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
        setShowDropdown(false);
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="language-selector">
            <div className="dropdown-container">
                <div className="selected-option" onClick={toggleDropdown}>
                    <div className="dropdown-option-icon">
                        {getLanguageOption(selectedLanguage).icon}
                    </div>
                    <div className="dropdown-option-label">
                        {getLanguageOption(selectedLanguage).label}
                    </div>
                </div>

                {showDropdown && (
                    <div className="options-container">
                        {languageOptions.map((option) => (
                            <div
                                key={option.value}
                                className="option"
                                onClick={() => changeLanguage(option.value)}
                            >
                                {option.icon}
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}






export function LanguageSwitcherIcons() {
    const { i18n } = useTranslation();

    const getCurrentLanguageIcon = useCallback(
        () => i18n.language || languageOptions[0].value,
        [i18n.language] // Add i18n.language as a dependency
    );
    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguageIcon());

    useEffect(() => {
        // Sync the component's state with i18n's current language whenever it changes
        setSelectedLanguage(getCurrentLanguageIcon());
    }, [ getCurrentLanguageIcon]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
    };

    return (
        <div className="language-icons-container">
            {languageOptions.map((option) => (
                <div
                    key={option.value}
                    className={`language-icon ${selectedLanguage === option.value ? 'selected' : ''}`}
                    onClick={() => changeLanguage(option.value)}
                    title={option.label}
                >
                    {option.icon}
                </div>
            ))}
        </div>
    );
}








export default LanguageSwitcherDropdown;
