import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useBrandsHook from '../../../api/brands'
import { ViewBrands } from '../../../components/admin/brands/ViewBrands';
import { FormBrands } from '../../../components/admin/brands/FormBrands';
// import useMachinesHook from '../../../api/machines';
import {checkImageFiles} from '../../../utils/imageTools';
import {useTranslation} from "react-i18next";

import {
    Spinner,
    Pagination,
    Message,
    Confirm, Search,
} from '../../../components'
import DefaultCategoriesIcon from "../../../media/vending-machine.svg";

const Brands = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')
    const {t} = useTranslation(); // Use i18n instance to access current language

    const {getBrands, postBrand, updateBrand, deleteBrand} = useBrandsHook({
        page,
        q,
    })

    // const {getMachines} = useMachinesHook({
    //     limit: 100000,
    //     page: 1,
    // })

    // const {data: dataMachines} = getMachines

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getBrands

    const {
        // data: responseUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateBrand

    const {
        // data: responseDelete,
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteBrand

    const {
        // data: responsePost,
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postBrand

    const formCleanHandler = (force = false) => {
        if (force || isSuccessPost || isSuccessUpdate) {
            console.log('clean handler true');
            setEdit(false);
            reset();
            setFileInputs([]); // Also reset the file inputs state
            setImageThumbnails([]); // Also reset the image thumbnails
        }
    }


    const [imageThumbnails, setImageThumbnails] = useState([]);
    const [imagesPath, setImagesPath] = useState({});

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate){
            setEdit(false);
        }
    }, [isSuccessPost, isSuccessUpdate])

    // Refetch data when the page changes
    // Refetch data when the search query changes
    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);


    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }

    const [fileInputs, setFileInputs] = useState([]);


    const submitHandler = async (itemData) => {
        try {
            console.log('submitHandler');

            console.log(fileInputs);

            if (edit) {

                const allImages = checkImageFiles(fileInputs);
                await mutateAsyncUpdate({
                    _id: id,
                    brand_id: itemData.brand_id,
                    brand_name: itemData.brand_name,
                    // machine: itemData.machine,
                    brandItems: itemData.brandItems,
                    images: allImages,
                });
                console.log('RESPONSE UPDATE')
            } else {

                // Check and prepare images
                const preparedImages = checkImageFiles(fileInputs);

                const payload = {
                    ...itemData,
                    images: preparedImages,
                };

                console.log('RESPONSE POST')
                await mutateAsyncPost(payload);
            }

            // Request succeeded
            if (edit) {
                console.log('SUCCESSFULL EDIT');
            } else {
                console.log('SUCCESSFULL NEW');
            }
        } catch (error) {
            // Request failed
            console.error(error);
            if (edit) {
                console.log('FAILED EDIT POST')

            } else {
                console.log('FAILED NEW POST')
            }
            if (error.response && error.response.status === 500) {
                console.log('FAILED POST 500')
            }
        }
    };

    const editHandler = (brand) => {
        console.log('editHandler');
        console.log(brand);
        setId(brand._id)
        setEdit(true);
        setValue('brand_id', brand.brand_id);
        // setValue('machine', brand.machine);
        setValue('brand_name', brand.brand_name);

        // setValue('brandItems', brand.brandItems);
        console.log('images');

        console.log(brand.images);
        setImageThumbnails(brand.images)
        setImagesPath(data.images_path);
        setValue('images', brand.images);

    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Brands</title>
                    <meta property='og:title' content='Brands' key='title'/>
                </Helmet>
            </HelmetProvider>

            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    {t("Brands List")} <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="categories-list-title-icon admin-list-title-icon" src={DefaultCategoriesIcon}
                         alt="Default Marker Icon"/>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#brandModal'
                        onClick={() => formCleanHandler(true)}
                    >
                        {t("Add New Brand")}
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder={t("Search by Name")}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>

            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewBrands
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                    setEdit={setEdit}
                    reset={reset}
                    useTranslation={useTranslation}
                    // images_path={imagesPath}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormBrands
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                isErrorUpdate={isErrorUpdate}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
                setValue={setValue}
                imageThumbnails={imageThumbnails}
                fileInputs={fileInputs}
                setFileInputs={setFileInputs}
                // dataMachines={dataMachines}
                images_path={imagesPath}
                useTranslation={useTranslation}

            />

        </>
    )
}

export default Brands
