import React, {useState, useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {confirmAlert} from 'react-confirm-alert';
import {useForm} from 'react-hook-form';
import useUserRolesHook from '../../../api/userRoles';
import useRolesHook from '../../../api/roles';
import useUsersHook from '../../../api/users';
import { Spinner, ViewUserRoles, Pagination, FormUserRoles, Message, Confirm, Search
} from '../../../components';

const UserRoles = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');

    const {getUserRoles, postUserRole, updateUserRole, deleteUserRole} = useUserRolesHook({page, q});

    const {getRoles} = useRolesHook({limit: 100000, page: 1});
    const {getUsers} = useUsersHook({limit: 100000, page: 1});

    const {register, handleSubmit, setValue, reset, formState: {errors}} = useForm({defaultValues: {auth: true}});

    const {data, isLoading, isError, error, refetch} = getUserRoles;
    const {data: dataRoles} = getRoles;
    const {data: dataUsers} = getUsers;

    const {isLoading: isLoadingUpdate, mutateAsync: mutateAsyncUpdate} = updateUserRole;
    const {mutateAsync: mutateAsyncDelete} = deleteUserRole; // Removed isLoadingDelete
    const {isLoading: isLoadingPost, mutateAsync: mutateAsyncPost} = postUserRole;

    const formCleanHandler = () => {
        setEdit(false);
        reset();
    };

    // Refetch data when the page changes
    // Refetch data when the search query changes
    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1);
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)));
    };

    const submitHandler = (data) => {
        if (edit) {
            mutateAsyncUpdate({
                _id: id,
                user: data.user,
                role: data.role,
            }).then(formCleanHandler);
        } else {
            mutateAsyncPost(data).then(formCleanHandler);
        }
    };

    const editHandler = (userRole) => {
        setId(userRole._id);
        setEdit(true);
        setValue('user', userRole.user && userRole.user._id);
        setValue('role', userRole.role && userRole.role._id);
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>UserRoles</title>
                    <meta property='og:title' content='UserRoles' key='title'/>
                </Helmet>
            </HelmetProvider>
            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    UserRoles List <sup className='fs-6'> [{data && data.total}] </sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#userRoleModal'
                        onClick={() => formCleanHandler()}
                    >
                        Add New UserRole
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by name'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>

            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewUserRoles
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormUserRoles
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                dataRoles={dataRoles}
                dataUsers={dataUsers}
            />
        </>
    );
};

export default UserRoles;
