import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router-dom';

import useMachinePriceRulesHook from '../../../api/machinePriceRules';
import { ViewMachinePriceRules } from '../../../components/admin/machine-price-rules/ViewMachinePriceRules';
import { FormMachinePriceRules } from '../../../components/admin/machine-price-rules/FormMachinePriceRules';

import {
    Spinner,
    Pagination,
    Message,
    Confirm,
    Search,
} from '../../../components';
import DefaultCategoriesIcon from '../../../media/vending-machine.svg';
import useCategoriesHook from "../../../api/productCategories";
import useProductsHook from "../../../api/products";
import {v4 as uuidv4} from "uuid";


const MachinePriceRules = () => {
    const { machine_id } = useParams();

    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const { t } = useTranslation();

    // Hooks must be called unconditionally
    const {
        getMachinePriceRules,
        updateMachinePriceRule,
        deleteMachinePriceRule,
        postMachinePriceRule,
    } = useMachinePriceRulesHook({
        machine_id,
        page,
        q,
    });

    const {mutateAsync: mutateAsyncUpdateMachinePriceRule} = updateMachinePriceRule;
    // const {mutateAsync: mutateAsyncDeleteMachinePriceRule} = deleteMachinePriceRule;
    // const {mutateAsync: mutateAsyncPostMachinePriceRule} = postMachinePriceRule;


    const [priceRules, setPriceRules] = useState([
        {
            _id: uuidv4(),
            ruleName: '',
            price: '',
            startDateTime: '',
            endDateTime: '',
            startTime: '',
            endTime: '',
            upc: '',
            ruleset: '',
            repeating_options: 'daily',
            active: true
        },
    ]);

    const { getProductCategories } = useCategoriesHook({ limit: 100000, page: 1 });
    const { data: productCategoriesData } = getProductCategories;

    const {getProducts} = useProductsHook({limit: 100000, page: 1});
    const {data: dataProducts} = getProducts;

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    const {
        data,
        isLoading,
        isError,
        error,
        refetch,
    } = getMachinePriceRules;

    const formCleanHandler = (force = false) => {
        if (force || postMachinePriceRule.isSuccess || updateMachinePriceRule.isSuccess) {
            setEdit(false);
            reset();
        }
    };

    // Refetch data when the page changes
    // Refetch data when the search query changes
    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1); // Reset to the first page after a search
    };

    const deleteHandler = (ruleId) => {
        confirmAlert(
            Confirm(() => deleteMachinePriceRule.mutate({ ruleId }), {
                title: t('Confirm Delete'),
                message: t('Are you sure you want to delete this price rule?'),
            })
        );
    };

    const submitHandler = async (data) => {
        console.log('SUBMIT_HANDLER');

        // console.log(data);
        // console.log(priceRules);

        if (edit) {
            const requestData = {
                _id: id,
                priceRules: priceRules,
                machine: data.machine
            };

            await mutateAsyncUpdateMachinePriceRule(requestData); // Update if editing
        } else {

            // await postMachinePriceRule(requestData); // Create new if not editing
            // formCleanHandler(true); // Reset form after successful submission
        }
    }

    const editHandler = (rule) => {
        setId(rule._id);
        setEdit(true);
        // Use optional chaining or provide a fallback if startDate, endDate, etc. are missing
        // setValue('priceRules', rule.priceRules || []);
        setPriceRules(rule.priceRules || []);
        setValue('machine', rule.machine || '');
    };


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Machine Price Rules</title>
                    <meta property="og:title" content="Machine Price Rules" key="title" />
                </Helmet>
            </HelmetProvider>

            <div className="view-list-top-bar">
                <h3 className="fw-light text-muted text-center mb-3">
                    {t('Machine Price Rules')}{' '}
                    <sup className="fs-6">[{data && data.total}]</sup>
                    <img
                        className="categories-list-title-icon admin-list-title-icon"
                        src={DefaultCategoriesIcon}
                        alt="Default Icon"
                    />
                </h3>

                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div
                        className="btn add-new-entry-button align-self-end is-disabled"
                    >
                        {t('Add New MachineRules')}
                    </div>
                    <Pagination data={data} setPage={setPage} />
                </div>

                <div className="col-auto full-search">
                    <Search
                        placeholder={t('Search by Rule Name')}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>

            {isLoading ? (
                <Spinner />
            ) : isError ? (
                <Message variant="danger">{error.message || 'An error occurred'}</Message>
            ) : (
                <ViewMachinePriceRules
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={deleteMachinePriceRule.isLoading}
                    useTranslation={useTranslation}
                />
            )}
            <div className="pagination-bottom-block">
                <Pagination data={data} setPage={setPage} />
            </div>

            <FormMachinePriceRules
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                isErrorUpdate={updateMachinePriceRule.isError}
                errors={errors}
                isLoadingUpdate={updateMachinePriceRule.isLoading}
                isLoadingPost={postMachinePriceRule.isLoading}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
                setValue={setValue}
                priceRules={priceRules}
                setPriceRules={setPriceRules}
                useTranslation={useTranslation}
                productCategoriesData={productCategoriesData}
                dataProducts={dataProducts}
            />
        </>
    );
};

export default MachinePriceRules;
