import { createContext, useReducer, useEffect, useRef, useCallback } from "react";

export const ResetContext = createContext();

const initialState = {
  countdown: 160,  // Initial countdown value
};

function timerReducer(state, action) {
  switch (action.type) {
    case 'START_COUNTDOWN':
      return { ...state, countdown: 160 };
    case 'RESET_COUNTDOWN':
      return { ...state, countdown: 160 };
    default:
      return state;
  }
}

export const ResetContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(timerReducer, initialState);
  const countdownRef = useRef(state.countdown);

  useEffect(() => {
    let interval = setInterval(() => {
      console.log(countdownRef.current);

      countdownRef.current -= 1;
      if (countdownRef.current <= 0) {
        clearInterval(interval);
        window.location.reload();
        //     window.location.href = `/kiosk/machine/${state.machine_id}`; // Sets the URL to the desired path
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const startCountdown = useCallback(() => {
    dispatch({ type: 'START_COUNTDOWN' });
    countdownRef.current = 160;  // Reset the countdown in ref
  }, []);

  const resetCountdown = useCallback(() => {
    dispatch({ type: 'RESET_COUNTDOWN' });
    countdownRef.current = 160;  // Reset the countdown in ref
  }, []);

  const getCountdown = () => countdownRef.current;

  return (
      <ResetContext.Provider value={{
        startCountdown,
        resetCountdown,
        getCountdown
      }}>
        {children}
      </ResetContext.Provider>
  );
};
