import React, {useContext, useState} from 'react'
import {FaTrash} from 'react-icons/fa'
import ShoppingContext from '../../context/ShoppingContext'
import {useTranslation} from 'react-i18next';
// import { ResetContext } from '../../../context/ResetContext';

const imagesPath = process.env.REACT_APP_BACKEND_URL + "/api/media/products/";

function CartItemModal({item, i18n}) {
    const {t} = useTranslation();
    const {removeCartItems, products, cart, addCartItems} = useContext(ShoppingContext)
    // const { resetCountdown } = useContext(ResetContext);  // Use resetCountdown from TimerContext

    const product = products.find(p => p._id === item.productId);


    const getCurrentItemQuantity = () => {
        // Ensure cart.orderItems is defined and is an array before proceeding
        if (Array.isArray(cart.orderItems)) {
            // console.log("SEARCHING FOR ITEM");
            // Find the cart item that matches the current product by its ID (or any unique identifier)
            const currentItem = cart.orderItems.find(cartItem => cartItem.productId.toString().trim() === product._id.toString().trim());
            // If the item is found, return its quantity (converted to a number to ensure it's numeric)
            if (currentItem) {
                // console.log("currentItem");
                // console.log(currentItem);
                return Number(currentItem.quantity) || 0;
            }
        }
        // Return 0 if cart.orderItems is not an array, is undefined, or the item is not found
        return 0;
    };



    const [quantity, setQuantity] = useState(getCurrentItemQuantity() || 0);
    const [maxQuantityReached, setMaxQuantityReached] = useState(false);
    const [maxQuantityReachedMessage, setMaxQuantityReachedMessage] = useState('');
    const [lastClickTime, setLastClickTime] = useState(0);
    const clickDelay = 100; // 200 milliseconds


    const maxTotalCartQuantity = 6; // Maximum total quantity allowed in the cart
    const maxProductQuantity = item.stock; // Assuming each product has a 'stock' property

    // Function to calculate the total quantity in the cart
    const getTotalCartQuantity = () => {
        // Ensure cart.orderItems is defined and is an array before proceeding
        if (Array.isArray(cart.orderItems)) {
            // Use reduce to sum up the quantity of each cart item
            return cart.orderItems.reduce((totalQuantity, cartItem) => {
                // Ensure cartItem.quantity is a number to avoid NaN errors
                const itemQuantity = Number(cartItem.quantity) || 0;
                return totalQuantity + itemQuantity;
            }, 0);
        }
        // Return 0 if cart.orderItems is not an array or is undefined
        return 0;
    };

    const updateQuantity = async (newQuantity) => {
        try {
            // resetCountdown();
            // Assuming addCartItems is an async function that returns a Promise
            await addCartItems({ ...product, quantity: newQuantity, itemPrice: product.price });
            // Here you can add any logic that should happen after successfully adding the item
        } catch (error) {
            // Handle any errors that occur during the add to cart process
            console.error("Failed to update quantity in cart", error);
            // Optionally, provide user feedback about the error
        }
    };

    const removeCartItemsHandler = (productId) => {
        removeCartItems(productId);
        // resetCountdown();
    };

    const changeQuantity = (change) => {
        console.log('changeQuantity');

        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime > clickDelay) {
            let newQuantity = quantity;
            let totalCartQuantity = getTotalCartQuantity();

            console.log('total');
            console.log(totalCartQuantity);
            if (change === 'increase') {
                // let lastQuantity = quantity;
                newQuantity = quantity + 1;
                if (totalCartQuantity + 1 > maxTotalCartQuantity) {
                    setMaxQuantityReached(true);
                    setMaxQuantityReachedMessage('Total cart quantity cannot exceed 6.');
                    return;
                } else if (newQuantity > maxProductQuantity) {
                    setMaxQuantityReached(true);
                    setMaxQuantityReachedMessage('Maximum stock for this product reached.');
                    return;
                } else {
                    setQuantity(newQuantity);
                    updateQuantity(newQuantity);
                    return;
                }
            } else if (change === 'decrease' && quantity >= 0) {

                if (quantity > 0) {
                    newQuantity = quantity - 1;
                    setQuantity(newQuantity);
                    updateQuantity(newQuantity);
                    return;
                } else {
                    // If decrease would set quantity below 0, do nothing
                }
            }
            setMaxQuantityReached(false);
            setMaxQuantityReachedMessage('');

            setLastClickTime(currentTime);
        }
    };



    return (
        <div className="cart-item-modal">
            <div className="cart-item-modal-inner">
                <div className="cart-item-modal-inner-left">
                    <div className="cart-item-modal-image">
                        {product.images && product.images.length > 0 && (
                            <img
                                src={`${imagesPath}${product.images[0].path}`}
                                alt={product.barcode}
                            />
                        )}
                    </div>
                </div>
                <div className="cart-item-modal-inner-middle">
                    <h3 className="cart-item-name">
                        {product.name?.[i18n.language] ?? product.name?.el}
                    </h3>
                    {/*<h3 className="cart-item-barcode">{item.barcode}</h3>*/}
                </div>

                <div className="cart-item-modal-inner-right">
                    <div className="cart-item-delete-button" onClick={() => removeCartItemsHandler(item.productId)}>
                        <FaTrash/>
                    </div>
                    <div className="cart-item-price">
                        {item.itemPrice.toFixed(2)}€
                    </div>
                </div>
                <div className="cart-item-modal-inner-bottom">
                    <div className="cart-item-modal-quantity-selector">
                        {maxQuantityReached && <div className="max-quantity-message-popup">
                            <div className="max-quantity-message-block">
                                <div className="max-quantity-message-icon">
                                    <img
                                        width='100'
                                        height='100'
                                        src='/img/tick-icon.png'
                                        className='img-fluid payment-icon'
                                        alt='logo'
                                    />
                                </div>
                                <div className="max-quantity-message-text">{t('Maximum quantity reached')}</div>
                                <div className="max-quantity-message-text-content">{t(maxQuantityReachedMessage)}</div>

                                <button className="max-quantity-message-button"
                                        onClick={() => setMaxQuantityReached(false)}>{t('OK')}</button>
                            </div>
                        </div>}
                        <div className="cart-item-quantity-selector-title">{t('Quantity')}</div>
                        <div className="item-details-quantity-selector-container">
                            <button className="quantity-less-button qty-select-button" onClick={() => changeQuantity('decrease')}>-</button>
                            <input className="cart-item-quantity-selector-input" type="text" value={quantity} readOnly/>
                            <button className="quantity-more-button qty-select-button" onClick={() => changeQuantity('increase')}>+</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CartItemModal