import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { confirmAlert } from 'react-confirm-alert'
import { useForm } from 'react-hook-form'
import useClientPermissionsHook from '../../../api/clientPermissions'
import {
  Spinner,
  ViewClientPermissions,
  Pagination,
  FormClientPermissions,
  Message,
  Confirm, Search,
} from '../../../components'

const ClientPermissions = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')

  const {
    getClientPermissions,
    postClientPermission,
    updateClientPermission,
    deleteClientPermission,
  } = useClientPermissionsHook({
    page,
    q,
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const { data, isLoading, isError, error, refetch } = getClientPermissions

  const {
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateClientPermission

  const {
    isLoading: isLoadingDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteClientPermission

  const {
    isLoading: isLoadingPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postClientPermission

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate){
      setEdit(false);
    }
  }, [isSuccessPost, isSuccessUpdate])

  // Refetch data when the page changes
  // Refetch data when the search query changes
  useEffect(() => {
    if (q.trim() || page) {
      refetch();
    }
  }, [page, q, refetch]);

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    confirmAlert(Confirm(() => mutateAsyncDelete(id)))
  }

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          name: data.name,
          menu: data.menu,
          path: data.path,
          description: data.description,
        })
      : mutateAsyncPost(data)
  }

  const editHandler = (clientPermission) => {
    setId(clientPermission._id)
    setEdit(true)
    setValue('name', clientPermission.name)
    setValue('menu', clientPermission.menu)
    setValue('path', clientPermission.path)
    setValue('description', clientPermission.description)
  }

  return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>Client Permissions</title>
            <meta property='og:title' content='Client Permissions' key='title'/>
          </Helmet>
        </HelmetProvider>

        <div className='view-list-top-bar'>

          <h3 className='fw-light text-muted text-center mb-3'>
            Client Permissions List{' '}
            <sup className='fs-6'> [{data && data.total}] </sup>
          </h3>

          <div className='d-flex align-items-center justify-content-between mb-2'>
            <button
                className='btn add-new-entry-button align-self-end'
                data-bs-toggle='modal'
                data-bs-target='#clientPermissionModal'
                onClick={() => formCleanHandler(true)}
            >
              Add New Client Permission
            </button>
            <Pagination data={data} setPage={setPage}/>
          </div>

          <div className='col-auto full-search'>
            <Search
                placeholder='Search by name'
                setQ={setQ}
                q={q}
                searchHandler={searchHandler}
            />
          </div>
        </div>


        {isLoading ? (
            <Spinner/>
        ) : isError ? (
            <Message variant='danger'>{error}</Message>
        ) : (
            <ViewClientPermissions
                data={data}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                isLoadingDelete={isLoadingDelete}
            />
        )}
        <div className='pagination-bottom-block'>
          <Pagination data={data} setPage={setPage}/>
        </div>
        <FormClientPermissions
            edit={edit}
            formCleanHandler={formCleanHandler}
            isLoading={isLoading}
            isError={isError}
            errors={errors}
            isLoadingUpdate={isLoadingUpdate}
            isLoadingPost={isLoadingPost}
            register={register}
            handleSubmit={handleSubmit}
            submitHandler={submitHandler}
            watch={watch}
            error={error}
        />
      </>
  )
}

export default ClientPermissions
