import {useEffect} from 'react';
import {v4 as uuidv4} from "uuid";

export const InputImagesBlock = ({
                                     imageThumbnails,
                                     label,
                                     name,
                                     register,
                                     setValue,
                                     fileInputs,
                                     setFileInputs,
                                     images_path, // Base path for images
                                     maxImages,
                                 }) => {

    useEffect(() => {
        // Initialize file inputs with existing images for editing
        if (imageThumbnails && imageThumbnails.length) {
            setFileInputs(imageThumbnails.map(image => ({
                id: uuidv4(),
                operation: "update",
                file: null,
                imageData: image.path,
                imagePath: `${images_path}${image.path}`
            })));
        }
    }, [imageThumbnails, images_path, setFileInputs]);

    useEffect(() => {
        // Register the images field in react-hook-form
        register(name);
    }, [register, name]);

    const addFileInput = () => {
        setFileInputs(prevInputs => {
            // Only add new input if the limit has not been reached
            if (prevInputs.length < maxImages) {
                return [...prevInputs, {id: uuidv4(), operation: "create", file: null}];
            }
            return prevInputs;
        });
    };

    const handleFileChange = (e, id) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const newInputs = fileInputs.map(input => {
                    if (input.id === id) {
                        return {
                            ...input,
                            operation: "create",
                            file: file,
                            imageData: reader.result,
                            imagePath: reader.result
                        };
                    }
                    return input;
                });
                setFileInputs(newInputs);
                // Update the form field with the new list of images
                updateFormImages(newInputs);
            };
            reader.readAsDataURL(file);
        }
    };


    const removeFileInput = (id) => {
        let updatedInputs = fileInputs.map(input => {
            if (input.id === id) {
                // Determine the action based on the input's current operation
                switch (input.operation) {
                    case 'create':
                        // For 'create', we actually want to remove the input completely
                        return null; // Mark for deletion, will be filtered out
                    case 'update':
                        // If currently 'update', mark this input's operation as 'remove'
                        return {...input, operation: 'remove'};
                    case 'remove':
                        // If already 'remove', switch back to 'update' as a safety, or handle as needed
                        return {...input, operation: 'update'};
                    default:
                        // No change for inputs that don't match the specific ID
                        return input;
                }
            }
            return input;
        }).filter(input => input !== null); // Filter out the inputs marked for deletion

        setFileInputs(updatedInputs);
        // Update the form field with the new list of images
        updateFormImages(updatedInputs);
    };

    // Synchronize file inputs with the form field
    const updateFormImages = (inputs) => {
        const imagesData = inputs.filter(input => input.file).map(input => input.file);
        setValue(name, imagesData); // Use setValue from react-hook-form to update the form field
    };

    return (
        <div className='mb-3'>

            <label>{label}</label>
            <div className="file-input-container mb-3">
                {fileInputs.map((input, index) => (

                    <div key={input.id} className="images-container-input" data-operation={input.operation}>
                        {console.log(index)}
                        <div className="thumbnail-input-img">
                            {input.imagePath && <img src={input.imagePath} alt="Thumbnail"/>}
                        </div>
                        <div className="thumbnail-container-input">
                            <input {...register(name)}
                                   type="file"
                                   className='form-control'
                                   id={input.id}
                                   onChange={e => handleFileChange(e, input.id)}
                            />
                        </div>
                        {input.operation === "remove" ? (
                            <button className="thumbnail-input-remove" type="button"
                                    onClick={() => removeFileInput(input.id)}>X</button>
                        ) : (
                            <button className="thumbnail-input-remove" type="button"
                                    onClick={() => removeFileInput(input.id)}>Remove</button>
                        )}
                    </div>
                ))}
                {fileInputs.length < maxImages && (
                    <button type="button" className="thumbnail-file-input-add" onClick={addFileInput}>Add Image</button>
                )}
            </div>
        </div>
    );


};


const isAllowedExtension = ['jpg', 'jpeg', 'png'];
const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
};


export const checkImageFiles = (fileInputs) => {
    const images = [];
    for (const fileInput of fileInputs) {
        // Ensure there is a file object to check
        if (!fileInput.imageData) {
            // isError(true);
            // setError('images', { type: 'manual', message: 'NO DATA FOUND TO SAVE FOR THIS IMAGE.' });
            console.log("NO imageData SET FOR IMAGE - WILL SKIP IT");
            continue;
        }
        if (fileInput.file && fileInput.file.type) {
            // Extract the file extension directly from the file name
            const fileExtension = getFileExtension(fileInput.file.name);
            if (!isAllowedExtension.includes(fileExtension)) {
                throw new Error(`Invalid file extension for ${fileInput.file.name}`);
            }
        }
        // Push the relevant data to the images array; adapt as needed
        images.push({
            id: fileInput.id,
            operation: fileInput.operation,
            imageData: fileInput.imageData
        });
        console.log('PUSHING IMAGES');
        console.log(images);
    }
    return images;
};
