import {useParams, useNavigate, Link, Route, Routes} from 'react-router-dom'
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Button} from "@mui/material";
import {ShoppingContextProvider} from './kiosk/context/ShoppingContext';
import {ResetContextProvider} from './kiosk/context/ResetContext';
import {CheckoutContextProvider} from './kiosk/context/CheckoutContext';

import KioskLandingPage from "./screens/kiosk/KioskLandingPage";
// import KioskCategories from "./screens/kiosk/KioskCategories";

const Kiosk = () => {
    const navigate = useNavigate();
    const { machine_id } = useParams();  // Get machine_id here
    return (
        <>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)}/>
                )}
                onError={console.error}
            >
            <ResetContextProvider>
                <CheckoutContextProvider>
                    <ShoppingContextProvider>
                        <div className="kiosk-container">
                            <Routes>
                                <Route path="/" element={<KioskLandingPage machine_id={machine_id} />} />
                                {/*<Route path='/machine/:machine_id' element={<KioskLandingPage/>}/>*/}
                                {/*<Route path='/machine/:id/category/:category_id' element={<KioskCategories/>}/>*/}
                                <Route path='/*' element={<KioskLandingPage/>}/>
                            </Routes>
                        </div>
                    </ShoppingContextProvider>
                </CheckoutContextProvider>
            </ResetContextProvider>

            </ErrorBoundary>
        </>
    )
}

    function ErrorFallback({error, goBack}) {
        const errorMessage = error && error.message ? error.message : "Unknown error";
        // const isDebugMode = process.env.NODE_ENV === 'development';
        // const errorStack = error && error.stack ? error.stack : "No stack trace";

        return (

            <div className="main-user-error-message-block">
                <div className="user-error-message-block">
                    <p>Something went wrong 😭</p>
                    <span>Error: {errorMessage}</span>
                    {/*{isDebugMode && (*/}
                    {/*    <>*/}
                    {/*        <p>Stack Trace:</p>*/}
                    {/*        <pre>{errorStack}</pre>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    <p>-</p>
                    <p>
                        <Link to={"/kiosk"} className="shop_go_to_shop_link">
                            GO TO SHOP
                        </Link>
                    </p>
                    <p>
                        <Button onClick={goBack}>Go Back</Button>
                    </p>
                </div>
            </div>
        );
    }

export default Kiosk
