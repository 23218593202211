import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useMachinesHook from '../../../api/machines'
import {ViewMachines} from '../../../components/admin/machines/ViewMachines';
import {FormMachines} from '../../../components/admin/machines/FormMachines';
import useProductsHook from '../../../api/products';
import {
    Spinner,
    Pagination,
    Message,
    Confirm, Search,
} from '../../../components'
import DefaultMachinesIcon from "../../../media/vending-machine.svg";
import {useTranslation} from "react-i18next";

const Machines = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const [triggerReset, setTriggerReset] = useState(false);
    const { t} = useTranslation(); // Use i18n instance to access current language

    const {
        getMachines,
        postMachine,
        updateMachine,
        deleteMachine,
        getMachineTotalStockById,
        getMachineTotalPriceById
    } = useMachinesHook({
        page,
        q,
    });
    const {getProducts} = useProductsHook({limit: 100000, page: 1});
    const {data: dataProducts} = getProducts;

    const {
        register ,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {
            errors
    }} = useForm({
        defaultValues: {confirmed: true, blocked: false},
    });

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getMachines;

    const {mutateAsync: mutateAsyncUpdate} = updateMachine;
    const {mutateAsync: mutateAsyncDelete} = deleteMachine;
    const {mutateAsync: mutateAsyncPost} = postMachine;

    const formCleanHandler = () => {
            setEdit(false);
            reset(); // Reset the form
            setTriggerReset(prev => !prev);
            setValue('items', '');
    };

    // Refetch data when the search query changes
    // Refetch data when the page changes
    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1); // Reset to the first page after a search
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id))); // Confirm and then delete
    };

    const submitHandler = async (data) => {
        if (edit) {
            const requestData = {
                _id: id, // Included in case of edit
                kiosk_external_id: data.kiosk_external_id,
                machine_name: data.machine_name,
                machine_phone: data.machine_phone,
                items: data.items ? JSON.parse(data.items) : [], // Parse string to array
                items_errors: data.items_errors ? JSON.parse(data.items_errors) : [],
                pre_items: data.pre_items ? JSON.parse(data.pre_items) : [],
                post_items: data.post_items ? JSON.parse(data.post_items) : [],
                pre_items_errors: data.pre_items_errors ? JSON.parse(data.pre_items_errors) : [],
                post_items_errors: data.post_items_errors ? JSON.parse(data.post_items_errors) : [],
                machineType: data.machineType,
                qr_code_receipt: data.qr_code_receipt,
                machineToAdminInventory: data.machineToAdminInventory,
                machineToAdminPlanogram: data.machineToAdminPlanogram,
                enablePriceRules: data.enablePriceRules,
                payment_options: data.payment_options,
                machine_status: data.machine_status,
                machine_order_id_processing: data.machine_order_id_processing,
                erp_id: data.erp_id,
                erp_token: data.erp_token,
                erp_doc_series: data.erp_doc_series,
                confirmed: data.confirmed,
                // blocked: data.blocked,
            };

            await mutateAsyncUpdate(requestData); // Update if editing
            // // Programmatically close the modal using vanilla JavaScript
            // const modal = document.getElementById('machineModal');
            // modal.classList.remove('show');
            // modal.style.display = 'none';
            // document.body.classList.remove('modal-open');
            // const modalBackdrop = document.querySelector('.modal-backdrop');
            // if (modalBackdrop) {
            //     modalBackdrop.remove();
            // }
        } else {
            const requestData = {
                kiosk_external_id: data.kiosk_external_id,
                machine_name: data.machine_name,
                machine_phone: data.machine_phone,
                items: data.items ? JSON.parse(data.items) : [], // Parse string to array
                items_errors: data.items_errors ? JSON.parse(data.items_errors) : [],
                pre_items: data.pre_items ? JSON.parse(data.pre_items) : [],
                post_items: data.post_items ? JSON.parse(data.post_items) : [],
                pre_items_errors: data.pre_items_errors ? JSON.parse(data.pre_items_errors) : [],
                post_items_errors: data.post_items_errors ? JSON.parse(data.post_items_errors) : [],
                machineType: data.machineType,
                qr_code_receipt: data.qr_code_receipt,
                machineToAdminInventory: data.machineToAdminInventory,
                machineToAdminPlanogram: data.machineToAdminPlanogram,
                enablePriceRules: data.enablePriceRules,
                payment_options: data.payment_options,
                machine_status: data.machine_status,
                machine_order_id_processing: data.machine_order_id_processing,
                erp_id: data.erp_id,
                erp_token: data.erp_token,
                erp_doc_series: data.erp_doc_series,
                confirmed: data.confirmed,
                // blocked: data.blocked,
            };

            await mutateAsyncPost(requestData); // Create new if not editing
        }
    };


    // const fetchTotalMachineStock = async (machineId) => {
    //     // Assuming you have a function similar to `dynamicAPI` for fetching
    //     try {
    //         const response = await dynamicAPI('get', `${url}/${machineId}/totalStock`, {});
    //         alert(`Total stock for machine ${machineId}: ${response.totalCurrentStock}`);
    //     } catch (error) {
    //         console.error("Failed to fetch total stock for machine", machineId, error);
    //         alert("Failed to fetch total stock");
    //     }
    // };


    const editHandler = (machine) => {
        formCleanHandler();
        setId(machine._id);
        setEdit(true); // Set editing mode
        setValue('kiosk_external_id', machine.kiosk_external_id);
        setValue('machine_name', machine.machine_name);
        setValue('machine_phone', machine.machine_phone);
        setValue('items', machine.items ? JSON.stringify(machine.items) : '');
        setValue('items_errors', machine.items_errors ? JSON.stringify(machine.items_errors) : '');
        setValue('pre_items', machine.pre_items ? JSON.stringify(machine.pre_items) : '');
        setValue('post_items', machine.post_items ? JSON.stringify(machine.post_items) : '');
        setValue('pre_items_errors', machine.pre_items_errors ? JSON.stringify(machine.pre_items_errors) : '');
        setValue('post_items_errors', machine.post_items_errors ? JSON.stringify(machine.post_items_errors) : '');
        setValue('machine_order_id_processing', machine.machine_order_id_processing);
        setValue('coins_total_stock', machine.coins_total_stock);
        setValue('bills_stock', machine.bills_stock ? JSON.stringify(machine.bills_stock) : '');
        setValue('erp_id', machine.erp_id);
        setValue('erp_token', machine.erp_token);
        setValue('erp_doc_series', machine.erp_doc_series);
        setValue('machine_status', machine.machine_status);
        setValue('machineType', machine.machineType);
        setValue('qr_code_receipt', machine.qr_code_receipt);
        setValue('machineToAdminInventory', machine.machineToAdminInventory);
        setValue('machineToAdminPlanogram', machine.machineToAdminPlanogram);
        setValue('enablePriceRules', machine.enablePriceRules);
        setValue('payment_options', machine.payment_options);
        setValue('confirmed', machine.confirmed);
        // setValue('blocked', machine.blocked);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Machines</title>
                    <meta property="og:title" content="Machines" key="title"/>
                </Helmet>
            </HelmetProvider>
            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    {t("Machines List")} <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="machines-list-title-icon admin-list-title-icon" src={DefaultMachinesIcon}
                         alt="Default Marker Icon"/>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#machineModal'
                        onClick={() => formCleanHandler()}
                    >
                        {t("Add New Machine")}
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by Code,Name'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewMachines
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    getMachineTotalStockById={getMachineTotalStockById}
                    getMachineTotalPriceById={getMachineTotalPriceById}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormMachines
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                dataProducts={dataProducts}
                dataMachines={data}
                setValue={setValue}
                triggerReset={triggerReset}
            />
        </>
    )
}
export default Machines
