import React, { useEffect } from 'react';
import { FormContainer } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InputEmail } from '../../utils/dynamicForm';
import useAuthHook from '../../api/auth';
import useAuth from '../../hooks/useAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ForgotPassword = () => {
    useAuth();  // Initialize authentication
    const { postForgotPassword } = useAuthHook();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const { isLoading , isSuccess, mutateAsync } = postForgotPassword;

    useEffect(() => {
        if (isSuccess) {
            reset();  // Reset the form on success
        }
    }, [isSuccess, reset]);

    useEffect(() => {
        if (auth?.userInfo) {
            navigate('/');  // Redirect if the user is already logged in
        }
    }, [auth?.userInfo, navigate]);

    const submitHandler = async (data) => {
        try {
            await mutateAsync(data);  // Handle form submission
        } catch (err) {
            console.error('Forgot password error:', err);
        }
    };

    return (
        <FormContainer>
            <HelmetProvider>
                <Helmet>
                    <title>Forgot Password</title>
                    <meta property='og:title' content='Forgot Password' key='title' />
                </Helmet>
            </HelmetProvider>

            <div className='fw-light font-monospace text-center'>
                <img
                    src='/img/forgot-password.png'
                    className='img-fluid forgot-password-icon'
                    alt='forgot-password-icon'
                />
            </div>

            <h3 className='fw-light font-monospace text-center'>Forgot Password</h3>


            <form onSubmit={handleSubmit(submitHandler)}>
                {InputEmail({
                    register,
                    errors,
                    label: 'Email',
                    name: 'email',
                    placeholder: 'Email',
                })}

                <button
                    type='submit'
                    className='btn btn-primary form-control'
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className='spinner-border spinner-border-sm' />
                    ) : (
                        'Send'
                    )}
                </button>
            </form>
        </FormContainer>
    );
};

export default ForgotPassword;
